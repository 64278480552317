module.exports = [{
      plugin: require('/opt/build/repo/baribuilder-ui/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/baribuilder-ui/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2046900505418306"},
    },{
      plugin: require('/opt/build/repo/baribuilder-ui/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104887163-2","head":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/baribuilder-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
